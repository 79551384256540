table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $very-light-grey;
  @include margin(rem(22) null);
  @include font(15);
  line-height: 1.4;

  @include mq($tablet) {
    @include font(16);
  }

  @include mq($desktop-plus) {
    @include font(18);
  }

  p,
  li {
    @include font(15);
    line-height: 1.4;

    @include mq($tablet) {
      @include font(16);
    }

    @include mq($desktop-plus) {
      @include font(18);
    }

  }//p, li

  tr {
    @include mq($tablet) {
      &:nth-child(even) {
        background-color: $white-smoke;

        td.active {
          background: $white-smoke;
        }
      }
    }
  }

	/**
	* Header Columns
	*/
	th {
    margin: 0;
    background: $white-smoke;
    border-right: 1px solid $very-light-grey;
    border-left: 1px solid $very-light-grey;
		padding: 15px;
		color: $nero;
    text-align: left;
    font-weight: bold;

    a {
      color: $persian-green;

      @include hover {
        text-decoration: none;
      }

    }//a

    &.active {
      background: $white-smoke;
    }

	}

	/**
	* Body Cells
	*/
	td {
    margin: 0;
    border-right: 1px solid $very-light-grey;
    border-left: 1px solid $very-light-grey;
    padding: 15px;

    &.active {
      background: $white;
    }

    p,
    li,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 1.4;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

	/**
	* Mobile only
	*/
	@include mq($mobile) {
    border: 0;
    border-top: 1px solid $very-light-grey;

    tr {
      border-bottom: 1px solid #ddd;
      display: block;
    }//tr

    // hide first Table Row (1 of following 3 options)
    > tr:first-of-type {
      display: none;
    }

    > thead {
      > tr:first-of-type {
      display: none;
      }
    }

    > tbody:only-child {
      > tr:first-of-type {
      display: none;
      }
    }

    td {
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: left;

      &::before {
        content: attr(data-label) '\003A\00a0';
        float: left;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:first-child {
        background-color: $white-smoke;
        color: $nero;
        font-weight: bold;

        &::before {
          display: none;
        }

      }

      &:not(:first-child) {

        p,
        ul,
        ol,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {

          &:only-child,
          &:first-child {
            margin-top: rem(30) !important;
          }

        }

      }//:not(:first-child)

      ul,
      ol {
        padding-left: rem(20);
      }

    }//td

  }//mobile

}//table
