@charset "UTF-8";
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 2, 2019 */
@font-face {
  font-family: 'berthold_akzidenz_grotesk_bLt';
  src: url("../fonts/berthold_akzidenz_grotesk_be_li_regular-webfont.woff2") format("woff2"), url("../fonts/berthold_akzidenz_grotesk_be_li_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
 */
html {
  font-size: 100%;
  line-height: 1.75em;
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  box-sizing: border-box; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Add the correct display in IE <10.
 * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
 * Add the correct display in IE for `main`.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * Add the correct display in IE <10.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display and remove excess height in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Add the correct display in IE <11, Safari <8, and Firefox <22.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox <40.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android <4.4.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2rem;
  line-height: 3.5rem;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 1.75rem 0; }

h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h3 {
  font-size: 1.17rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h4 {
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h5 {
  font-size: 0.83rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h6 {
  font-size: 0.67rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

/**
 * Add the correct background and color in IE <10.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE <11.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
   * Set 1 unit of vertical rhythm on the top and bottom margin.
   */
blockquote {
  margin: 1.75rem 40px; }

/**
   * Address margins set differently in IE 6/7.
   */
dl,
menu,
ol,
ul {
  margin: 1.75rem 0; }

/**
     * Turn off margins on nested lists.
     */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0; }

dd {
  margin: 0 0 0 40px; }

/**
   * Address paddings set differently in IE 6/7.
   */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1.75rem 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
   * Set 1 unit of vertical rhythm on the top and bottom margin.
   */
p,
pre {
  margin: 1.75rem 0; }
  p:first-child,
  pre:first-child {
    margin-top: 0; }
  p:last-child,
  pre:last-child {
    margin-bottom: 0; }

/* Forms
   ========================================================================== */
/**
 * Known issues:
 * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
 *   select, unless a border property is set. The default font weight on
 *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
 *   OS X.
 * - It is recommended that you do not style checkbox and radio inputs as
 *   Firefox's implementation does not respect box-sizing, padding, or width.
 * - Certain font size values applied to number inputs cause the cursor style of
 *   the decrement button to change from default to text.
 * - The search input is not fully stylable by default. In Chrome and Safari on
 *   OSX/iOS you can't control font, padding, border, or background. In Chrome
 *   and Safari on Windows you can't control border properly. It will apply
 *   border-width but will only show a border color (which cannot be controlled)
 *   for the outer 1px of that border. Applying -webkit-appearance: textfield
 *   addresses these issues without removing the benefits of search inputs (e.g.
 *   showing past searches). Safari (but not Chrome) will clip the cancel button
 *   on when it has padding (and textfield appearance).
 */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
 * 4. Improve appearance and consistency with IE 6/7.
 */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible; }

/**
 * 1. Add the correct box sizing in IE <11.
 * 2. Remove the padding in IE <11.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
   */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 * 4. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */ }

/**
 * Restore the font weight unset by a previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
* Animation Mixins
*/
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes search-open {
  from {
    opacity: 0;
    height: 0; }
  to {
    opacity: 1;
    height: 104px; } }

@keyframes sub-menu-down {
  from {
    opacity: 0;
    top: 0; }
  to {
    opacity: 1;
    top: 60px; } }

.clearfix::after {
  display: block;
  clear: both;
  content: "" !important; }

/**
 * Returns a percentage width based on the number and total number of columns
 *
 * @param   int     $num    Number of columns (e.g. 4)
 * @param   int     $total  Total number of columns (e.g. 24)
 * @return  float            Total width (%)
 */
/*  Unitless line-height
*
*   @param {Font Size} $fontSize - Unitless font size
*   @param {Leading}   $leading  - Unitless leading from photoshop or sketch
*
*   Example Usage:
*
*     @include line-height(16, 20);
*
*/
/*  Remove List Styles
*
*   No Params
*
*   Example Usage:
*
*     ul {
*       @include no-bullet;
*     }
*
*/
/*  Media Query Mixin
*
*   @param {Media Query} $mq - Media query in single quotes: eg. 'screen and (min-width: 480px)'
*
*   Example Usage:
*
*     .selector {
*       property: value;
*
*       @include mq($bp768) {
*         property: value;
*       }
*     }
*
*/
/*  Placeholder Mixin
*
*   No Params
*
*   Example Usage:
*
*     input[placeholder] {
*       @include placeholder {
*         color: red;
*       }
*     }
*
*/
/*  PX to REM Conversion
*
*   @param {Pixel} $px - Unitless pixel value to be converted into rems.
*
*   Example Usage:
*
*     margin-bottom: rem(20);
*
*/
/**
 * Hide an element, whilst making its content available to screen readers
 * and meeting WCAG AA guidelines
 */
/**
* Fonts
*/
.visually-hidden,
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden; }

a[href],
button,
input,
select,
textarea,
object {
  outline-color: #1879CD; }
  .keyboard-user a[href]:focus, .keyboard-user
  button:focus, .keyboard-user
  input:focus, .keyboard-user
  select:focus, .keyboard-user
  textarea:focus, .keyboard-user
  object:focus {
    box-shadow: 0 0 0 0.375rem #1879cd;
    border-radius: 8px; }
  .keyboard-user.pointerevents a[href]:focus:hover, .keyboard-user.pointerevents
  button:focus:hover, .keyboard-user.pointerevents
  input:focus:hover, .keyboard-user.pointerevents
  select:focus:hover, .keyboard-user.pointerevents
  textarea:focus:hover, .keyboard-user.pointerevents
  object:focus:hover {
    box-shadow: none; }

.skip-link {
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 15px;
  z-index: 9999;
  background: #F40606;
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold; }
  .skip-link:focus, .skip-link:active, .skip-link:hover {
    transition: 250ms ease-in-out;
    text-decoration: none; }
  .skip-link:not(:focus) {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden; }

label {
  display: block;
  color: #666666;
  margin-bottom: 0.1875rem; }
  label .form-required {
    color: #666666; }
  @media screen and (min-width: 620px) {
    label {
      font-size: 1.125rem; } }

select {
  appearance: none;
  line-height: normal;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(151, 151, 151, 0.34);
  padding: 15px 35px 15px 10px;
  font-weight: bold;
  font-size: 1rem;
  background: #efefef url("../images/icon-select.svg") no-repeat;
  background-position: right 10px top 50%;
  background-size: 10px; }
  @media screen and (max-width: 619px) {
    select {
      width: 100%; } }
  select::-ms-expand {
    display: none; }

input[type=text],
.date-input,
input[type=email],
input[type=phone],
input[type=password],
input[type=file],
textarea {
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(151, 151, 151, 0.34);
  padding: 9px 15px;
  font-size: 1.25rem;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 619px) {
    input[type=text],
    .date-input,
    input[type=email],
    input[type=phone],
    input[type=password],
    input[type=file],
    textarea {
      width: 100%; } }
  input[type=text]::-webkit-input-placeholder,
  .date-input::-webkit-input-placeholder,
  input[type=email]::-webkit-input-placeholder,
  input[type=phone]::-webkit-input-placeholder,
  input[type=password]::-webkit-input-placeholder,
  input[type=file]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #767676; }
  input[type=text]:-ms-input-placeholder,
  .date-input:-ms-input-placeholder,
  input[type=email]:-ms-input-placeholder,
  input[type=phone]:-ms-input-placeholder,
  input[type=password]:-ms-input-placeholder,
  input[type=file]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #767676; }
  input[type=text]::placeholder,
  .date-input::placeholder,
  input[type=email]::placeholder,
  input[type=phone]::placeholder,
  input[type=password]::placeholder,
  input[type=file]::placeholder,
  textarea::placeholder {
    color: #767676; }
  .sidebar input[type=text], .sidebar
  .date-input, .sidebar
  input[type=email], .sidebar
  input[type=phone], .sidebar
  input[type=password], .sidebar
  input[type=file], .sidebar
  textarea {
    width: 100%; }

input[type="submit"] {
  display: inline-block;
  height: auto;
  padding: 0.9375rem 20px;
  font-family: berthold_akzidenz_grotesk_bLt;
  font-weight: bold;
  text-transform: uppercase;
  background: #009C8A;
  color: #FFFFFF !important;
  border-radius: 8px;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1;
  background-clip: padding-box;
  border: 0; }
  @media screen and (min-width: 620px) {
    input[type="submit"] {
      padding: 1.25rem 30px; } }
  .sidebar input[type="submit"] {
    width: 100%; }
  @media screen and (max-width: 619px) {
    input[type="submit"] {
      width: 100%; } }
  input[type="submit"]:focus, input[type="submit"]:active, input[type="submit"]:hover {
    transition: 250ms ease-in-out;
    text-decoration: none;
    background-color: #1879CD;
    color: #FFFFFF !important; }

label {
  position: relative; }
  label input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: 2px solid #000000;
    appearance: none;
    width: 26px;
    height: 26px;
    background-color: #FFFFFF; }
    label input[type=checkbox]:checked {
      border-color: #767676; }
      label input[type=checkbox]:checked::after {
        content: '';
        width: 18px;
        height: 18px;
        background-color: #767676;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    label input[type=checkbox]:hover {
      border-color: #000000; }

input[type="date"]::-webkit-inner-spin-button {
  display: none; }

.form-item,
.mc-field-group {
  margin-bottom: 1.25rem; }
  @media screen and (min-width: 620px) {
    .form-item,
    .mc-field-group {
      margin-bottom: 1.875rem; } }
  @media screen and (min-width: 1024px) {
    .form-item,
    .mc-field-group {
      margin-bottom: 2.5rem; }
      .sidebar-first .form-item,
      .sidebar-second .form-item, .sidebar-first
      .mc-field-group,
      .sidebar-second
      .mc-field-group {
        margin-bottom: 1.25rem; } }
  .form-item.form-type-checkbox, .form-item.form-type-radio,
  .form-item.input-group ul > li,
  .mc-field-group.form-type-checkbox,
  .mc-field-group.form-type-radio,
  .mc-field-group.input-group ul > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.9375rem; }
    .form-item.form-type-checkbox > label, .form-item.form-type-radio > label,
    .form-item.input-group ul > li > label,
    .mc-field-group.form-type-checkbox > label,
    .mc-field-group.form-type-radio > label,
    .mc-field-group.input-group ul > li > label {
      margin: 0.125rem 0 0 0.9375rem; }
  .form-item .description,
  .mc-field-group .description {
    color: #767676;
    font-size: 0.875rem;
    line-height: 1.2858;
    margin-top: 5px;
    font-style: italic; }
    @media screen and (min-width: 620px) {
      .form-item .description,
      .mc-field-group .description {
        font-size: 0.9375rem;
        line-height: 1.3334; } }
    .sidebar-first .form-item .description,
    .sidebar-second .form-item .description, .sidebar-first
    .mc-field-group .description,
    .sidebar-second
    .mc-field-group .description {
      margin-bottom: 0.625rem; }

.form-actions {
  margin-top: 1.875rem; }
  @media screen and (min-width: 620px) {
    .form-actions {
      margin-top: 2.5rem; } }
  @media screen and (min-width: 1024px) {
    .form-actions {
      margin-top: 3.125rem; } }

* {
  -webkit-overflow-scrolling: touch; }

html,
body {
  overflow-x: hidden;
  background: #000000; }
  html.slideshow-open .layout-container,
  body.slideshow-open .layout-container {
    position: fixed; }

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available; }

html {
  height: -webkit-fill-available; }

main {
  min-height: 500px; }
  main::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (max-width: 1023px) {
    main {
      position: relative;
      z-index: 2;
      min-height: 300px; } }
  @media screen and (min-width: 1024px) {
    body.fixed-nav main {
      padding-top: 60px; } }

article {
  max-width: 1540px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  article::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (min-width: 620px) {
    article {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1024px) {
    article {
      padding-right: 50px;
      padding-left: 50px; } }

.layout-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  transition: 250ms ease-in-out; }
  @media screen and (max-width: 1023px) {
    body.mobile-nav-open .layout-container {
      position: fixed;
      width: 100%; } }

.layout-inner-container {
  position: relative;
  max-width: 1540px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  .layout-inner-container::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (min-width: 620px) {
    .layout-inner-container {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1024px) {
    .layout-inner-container {
      padding-right: 50px;
      padding-left: 50px; } }

figure img,
.image img {
  display: block;
  width: 100%;
  height: auto; }

.thumb-list .views-row {
  margin-bottom: 1.875rem; }
  @media screen and (min-width: 620px) {
    .thumb-list .views-row {
      margin-bottom: 2.5rem; } }
  @media screen and (min-width: 1350px) {
    .thumb-list .views-row {
      margin-bottom: 3.5rem; } }

/* ==========================================================================
 Print styles.
 https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css
 ========================================================================== */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .breadcrumb a[href]:after {
    content: none; } }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-size: 100%;
  line-height: 1.5; }

.menu {
  list-style: none;
  padding: 0; }
  .menu li {
    padding: 0;
    margin: 0; }

.thumb-list ul,
.item-list ul {
  list-style: none;
  padding: 0; }

nav ul,
nav ol {
  list-style: none;
  padding: 0; }
  nav ul li,
  nav ol li {
    padding: 0;
    margin: 0; }

dl {
  margin-top: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

address {
  font-style: normal; }

form {
  margin: 0; }

fieldset {
  padding: 0;
  margin: 0;
  border: 0; }

textarea {
  resize: vertical; }

button {
  background-color: transparent;
  border: 0;
  padding: 0; }

[type="search"]::-ms-clear {
  display: none; }

iframe {
  border: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th,
td {
  padding: 0; }

th {
  font-weight: normal; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
  font-size: 0.9375rem;
  line-height: 1.4;
  /**
	* Header Columns
	*/
  /**
	* Body Cells
	*/
  /**
	* Mobile only
	*/ }
  @media screen and (min-width: 620px) {
    table {
      font-size: 1rem; } }
  @media screen and (min-width: 1350px) {
    table {
      font-size: 1.125rem; } }
  table p,
  table li {
    font-size: 0.9375rem;
    line-height: 1.4; }
    @media screen and (min-width: 620px) {
      table p,
      table li {
        font-size: 1rem; } }
    @media screen and (min-width: 1350px) {
      table p,
      table li {
        font-size: 1.125rem; } }
  @media screen and (min-width: 620px) {
    table tr:nth-child(even) {
      background-color: #efefef; }
      table tr:nth-child(even) td.active {
        background: #efefef; } }
  table th {
    margin: 0;
    background: #efefef;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 15px;
    color: #212121;
    text-align: left;
    font-weight: bold; }
    table th a {
      color: #009C8A; }
      table th a:focus, table th a:active, table th a:hover {
        transition: 250ms ease-in-out;
        text-decoration: none; }
    table th.active {
      background: #efefef; }
  table td {
    margin: 0;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 15px; }
    table td.active {
      background: #FFFFFF; }
    table td p,
    table td li,
    table td ol,
    table td ul,
    table td h1,
    table td h2,
    table td h3,
    table td h4,
    table td h5 {
      line-height: 1.4; }
      table td p:first-of-type,
      table td li:first-of-type,
      table td ol:first-of-type,
      table td ul:first-of-type,
      table td h1:first-of-type,
      table td h2:first-of-type,
      table td h3:first-of-type,
      table td h4:first-of-type,
      table td h5:first-of-type {
        margin-top: 0; }
      table td p:last-child,
      table td li:last-child,
      table td ol:last-child,
      table td ul:last-child,
      table td h1:last-child,
      table td h2:last-child,
      table td h3:last-child,
      table td h4:last-child,
      table td h5:last-child {
        margin-bottom: 0; }
  @media screen and (max-width: 619px) {
    table {
      border: 0;
      border-top: 1px solid #ccc; }
      table tr {
        border-bottom: 1px solid #ddd;
        display: block; }
      table > tr:first-of-type {
        display: none; }
      table > thead > tr:first-of-type {
        display: none; }
      table > tbody:only-child > tr:first-of-type {
        display: none; }
      table td {
        border-bottom: 1px solid #ddd;
        display: block;
        text-align: left; }
        table td::before {
          content: attr(data-label) ": ";
          float: left; }
        table td:last-child {
          border-bottom: 0; }
        table td:first-child {
          background-color: #efefef;
          color: #212121;
          font-weight: bold; }
          table td:first-child::before {
            display: none; }
        table td:not(:first-child) p:only-child, table td:not(:first-child) p:first-child,
        table td:not(:first-child) ul:only-child,
        table td:not(:first-child) ul:first-child,
        table td:not(:first-child) ol:only-child,
        table td:not(:first-child) ol:first-child,
        table td:not(:first-child) h1:only-child,
        table td:not(:first-child) h1:first-child,
        table td:not(:first-child) h2:only-child,
        table td:not(:first-child) h2:first-child,
        table td:not(:first-child) h3:only-child,
        table td:not(:first-child) h3:first-child,
        table td:not(:first-child) h4:only-child,
        table td:not(:first-child) h4:first-child,
        table td:not(:first-child) h5:only-child,
        table td:not(:first-child) h5:first-child,
        table td:not(:first-child) h6:only-child,
        table td:not(:first-child) h6:first-child {
          margin-top: 1.875rem !important; }
        table td ul,
        table td ol {
          padding-left: 1.25rem; } }

body {
  color: #212121;
  font-family: berthold_akzidenz_grotesk_bLt;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  cursor: pointer;
  color: #1879CD;
  text-decoration: none; }
  a:focus, a:active, a:hover {
    transition: 250ms ease-in-out;
    text-decoration: underline; }

h1 {
  font-weight: normal;
  font-size: 1.625rem;
  line-height: 1.1539;
  margin-top: 0; }
  @media screen and (min-width: 620px) {
    h1 {
      font-size: 1.875rem;
      line-height: 1.1334; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 2.5rem;
      line-height: 1.1; } }
  @media screen and (min-width: 1212px) {
    h1 {
      font-size: 2.75rem;
      line-height: 1.091; } }
  @media screen and (min-width: 1350px) {
    h1 {
      font-size: 2.875rem;
      line-height: 1.087; } }

h2 {
  margin-top: 1.875rem;
  font-weight: normal;
  text-transform: none;
  font-size: 1.5rem;
  line-height: 1.1667; }
  @media screen and (min-width: 620px) {
    h2 {
      margin-top: 2.25rem;
      font-size: 1.75rem;
      line-height: 1.1429; } }
  @media screen and (min-width: 1024px) {
    h2 {
      margin-top: 2.625rem;
      font-size: 2rem;
      line-height: 1.125; } }
  @media screen and (min-width: 1350px) {
    h2 {
      margin-top: 3.125rem;
      font-size: 2.25rem;
      line-height: 1.1112; } }

h3 {
  margin: 1.5rem 0 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: #767676;
  font-size: 1.25rem;
  line-height: 1.4; }
  @media screen and (min-width: 620px) {
    h3 {
      margin: 1.875rem 0 0.9375rem;
      font-size: 1.5rem;
      line-height: 1.4167; } }
  @media screen and (min-width: 1024px) {
    h3 {
      margin-top: 2.25rem;
      font-size: 1.75rem;
      line-height: 1.3572; } }
  @media screen and (min-width: 1350px) {
    h3 {
      margin-top: 2.5rem; } }

h4 {
  font-weight: bold;
  margin: 1rem 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.3334; }
  @media screen and (min-width: 620px) {
    h4 {
      margin: 1.875rem 0 0.9375rem;
      font-size: 1.3125rem; } }
  @media screen and (min-width: 1024px) {
    h4 {
      font-size: 1.5rem; } }

h5,
h6 {
  font-weight: bold;
  margin: 1rem 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.4286; }
  @media screen and (min-width: 620px) {
    h5,
    h6 {
      margin: 1.875rem 0 0.9375rem;
      font-size: 1.25rem;
      line-height: 1.4; } }

p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.9375rem;
  line-height: 1.6; }
  @media screen and (min-width: 620px) {
    p {
      margin-top: 1.75rem;
      margin-bottom: 1.75rem;
      font-size: 1rem;
      line-height: 1.75; } }
  @media screen and (min-width: 1024px) {
    p {
      font-size: 1.125rem;
      line-height: 1.8889; } }
  @media screen and (min-width: 1350px) {
    p {
      font-size: 1.25rem;
      line-height: 1.8; } }

strong {
  font-weight: bold; }

blockquote {
  position: relative;
  padding: 0 8.33333% 0 8.33333%;
  margin: 1.25rem 0;
  font-size: 1.125rem;
  line-height: 1.3334;
  color: #767676;
  font-style: italic; }
  @media screen and (min-width: 620px) {
    blockquote {
      font-size: 1.875rem;
      line-height: 1.2;
      margin: 1.25rem 0 2.25rem; } }
  @media screen and (min-width: 1024px) {
    blockquote {
      font-size: 2.25rem;
      line-height: 1.1945;
      margin: 2.5rem 0 2.875rem; } }
  blockquote p {
    font-size: 1.125rem;
    line-height: 1.3334; }
    @media screen and (min-width: 620px) {
      blockquote p {
        font-size: 1.875rem;
        line-height: 1.2; } }

h3.alt, h4.alt {
  font-size: 0.9375rem;
  line-height: 1.4667; }
  @media screen and (min-width: 620px) {
    h3.alt, h4.alt {
      font-size: 1rem; } }
  @media screen and (min-width: 1024px) {
    h3.alt, h4.alt {
      font-size: 1.125rem; } }
  @media screen and (min-width: 1350px) {
    h3.alt, h4.alt {
      font-size: 1.25rem; } }

.button-container,
.button-container:last-child {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important; }
  @media screen and (min-width: 620px) {
    .button-container,
    .button-container:last-child {
      margin-top: 2.375rem !important;
      margin-bottom: 2.375rem !important; } }

figcaption {
  font-size: 0.9375rem;
  line-height: 1.4667; }
  @media screen and (min-width: 620px) {
    figcaption {
      font-size: 1rem;
      line-height: 1.25; } }
  @media screen and (min-width: 1350px) {
    figcaption {
      font-size: 1.125rem;
      line-height: 1.3334; } }

.addthis-smartlayers #at4-share,
.addthis-smartlayers #at-share-dock {
  display: none !important; }
  .addthis-smartlayers #at4-share body.mobile-nav-open,
  .addthis-smartlayers #at-share-dock body.mobile-nav-open {
    z-index: -1 !important; }
  @media screen and (min-width: 620px) and (max-width: 1023px) {
    body.search-bar-open .addthis-smartlayers #at4-share, body.search-bar-open
    .addthis-smartlayers #at-share-dock {
      z-index: 10 !important; } }
  @media screen and (min-width: 620px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 201px !important; } }
  @media screen and (min-width: 1024px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 296px !important; } }
  @media screen and (min-width: 1212px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 309px !important; } }
  @media screen and (min-width: 1350px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 317px !important; } }
  .addthis-smartlayers #at4-share > a,
  .addthis-smartlayers #at-share-dock > a {
    border: solid 1px #ccc; }
    @media screen and (min-width: 620px) {
      .addthis-smartlayers #at4-share > a:nth-child(2),
      .addthis-smartlayers #at-share-dock > a:nth-child(2) {
        top: -1px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(3),
      .addthis-smartlayers #at-share-dock > a:nth-child(3) {
        top: -2px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(4),
      .addthis-smartlayers #at-share-dock > a:nth-child(4) {
        top: -3px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(5),
      .addthis-smartlayers #at-share-dock > a:nth-child(5) {
        top: -4px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(6),
      .addthis-smartlayers #at-share-dock > a:nth-child(6) {
        top: -5px !important; } }

.node-type-event #at4-share,
.node-type-event #at-share-dock,
.node-type-article #at4-share,
.node-type-article #at-share-dock,
.node-type-news-article #at4-share,
.node-type-news-article #at-share-dock,
.node-type-resource #at4-share,
.node-type-resource #at-share-dock,
.node-type-ta-program #at4-share,
.node-type-ta-program #at-share-dock,
.node-type-curriculum #at4-share,
.node-type-curriculum #at-share-dock {
  display: flex !important; }
  @media screen and (min-width: 620px) {
    .node-type-event #at4-share,
    .node-type-event #at-share-dock,
    .node-type-article #at4-share,
    .node-type-article #at-share-dock,
    .node-type-news-article #at4-share,
    .node-type-news-article #at-share-dock,
    .node-type-resource #at4-share,
    .node-type-resource #at-share-dock,
    .node-type-ta-program #at4-share,
    .node-type-ta-program #at-share-dock,
    .node-type-curriculum #at4-share,
    .node-type-curriculum #at-share-dock {
      display: block !important; } }
  @media screen and (min-width: 620px) and (max-width: 1023px) {
    .no-pointerevents .node-type-event #at4-share, .no-pointerevents
    .node-type-event #at-share-dock, .no-pointerevents
    .node-type-article #at4-share, .no-pointerevents
    .node-type-article #at-share-dock, .no-pointerevents
    .node-type-news-article #at4-share, .no-pointerevents
    .node-type-news-article #at-share-dock, .no-pointerevents
    .node-type-resource #at4-share, .no-pointerevents
    .node-type-resource #at-share-dock, .no-pointerevents
    .node-type-ta-program #at4-share, .no-pointerevents
    .node-type-ta-program #at-share-dock, .no-pointerevents
    .node-type-curriculum #at4-share, .no-pointerevents
    .node-type-curriculum #at-share-dock {
      display: none !important; } }

.bar {
  position: absolute;
  z-index: 1;
  transition: 250ms ease-in-out;
  display: block;
  background: #FFFFFF;
  width: 220px;
  height: 6px;
  left: 15px;
  bottom: 200px; }
  @media screen and (min-width: 620px) {
    .bar {
      width: 300px;
      left: 30px; } }
  @media screen and (min-width: 1024px) {
    .bar {
      left: 50px; } }
  @media screen and (min-width: 1212px) {
    .bar {
      width: 400px; } }
  @media screen and (min-width: 1350px) {
    .bar {
      width: 480px; } }

.button {
  display: inline-block;
  height: auto;
  padding: 0.9375rem 20px;
  font-family: berthold_akzidenz_grotesk_bLt;
  font-weight: bold;
  text-transform: uppercase;
  background: #1E88E5;
  color: #FFFFFF !important;
  border-radius: 8px;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1;
  background-clip: padding-box;
  border: 0; }
  @media screen and (min-width: 620px) {
    .button {
      padding: 1.25rem 30px; } }
  .sidebar .button {
    width: 100%; }
  @media screen and (max-width: 619px) {
    .button {
      width: 100%; } }
  .button:focus, .button:active, .button:hover {
    transition: 250ms ease-in-out;
    text-decoration: none;
    background-color: #1879CD;
    color: #FFFFFF !important; }
  .button span {
    text-transform: lowercase; }

.forum-create-link-new a {
  display: inline-block;
  height: auto;
  padding: 0.9375rem 20px;
  font-family: berthold_akzidenz_grotesk_bLt;
  font-weight: bold;
  text-transform: uppercase;
  background: #1E88E5;
  color: #FFFFFF !important;
  border-radius: 8px;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1;
  background-clip: padding-box;
  border: 0; }
  @media screen and (min-width: 620px) {
    .forum-create-link-new a {
      padding: 1.25rem 30px; } }
  .sidebar .forum-create-link-new a {
    width: 100%; }
  @media screen and (max-width: 619px) {
    .forum-create-link-new a {
      width: 100%; } }
  .forum-create-link-new a:focus, .forum-create-link-new a:active, .forum-create-link-new a:hover {
    transition: 250ms ease-in-out;
    text-decoration: none;
    background-color: #1879CD;
    color: #FFFFFF !important; }

.hamburger-menu {
  position: static;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;
  background-color: transparent;
  line-height: inherit;
  text-align: inherit;
  color: #000000;
  outline: 0;
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  width: 55px;
  height: 70px;
  /**
   * Close
   */ }
  @media screen and (min-width: 620px) {
    .hamburger-menu {
      width: 92px;
      height: 90px; } }
  @media screen and (min-width: 1024px) {
    .hamburger-menu {
      width: 112px;
      top: 40px; } }
  .hamburger-menu .icon {
    display: block;
    width: 25px;
    height: 15px;
    position: relative;
    transform: rotate(0deg);
    transition: 250ms ease-in-out; }
    @media screen and (min-width: 620px) {
      .hamburger-menu .icon {
        width: 32px;
        height: 20px; } }
    .hamburger-menu .icon span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #FFFFFF;
      opacity: 1;
      left: 15px;
      transform: rotate(0deg);
      transition: 250ms ease-in-out;
      animation: fade-in 250ms ease-in-out; }
      body.white .hamburger-menu .icon span {
        background: #000000; }
      @media screen and (min-width: 620px) {
        .hamburger-menu .icon span {
          left: 30px;
          height: 3px; } }
      .hamburger-menu .icon span:nth-child(1) {
        top: 0px; }
      .hamburger-menu .icon span:nth-child(2), .hamburger-menu .icon span:nth-child(3) {
        top: 6px;
        background: transparent; }
        body.white .hamburger-menu .icon span:nth-child(2), body.white .hamburger-menu .icon span:nth-child(3) {
          background: transparent; }
        @media screen and (min-width: 620px) {
          .hamburger-menu .icon span:nth-child(2), .hamburger-menu .icon span:nth-child(3) {
            top: 8px; } }
      .hamburger-menu .icon span:nth-child(4) {
        top: 10px; }
        @media screen and (min-width: 620px) {
          .hamburger-menu .icon span:nth-child(4) {
            top: 14px; } }
  .hamburger-menu.active .icon span {
    font-size: 0 !important;
    line-height: 0 !important;
    text-shadow: unset !important; }
    .hamburger-menu.active .icon span:nth-child(1) {
      display: none; }
    .hamburger-menu.active .icon span:nth-child(2) {
      transform: rotate(45deg);
      background: #FFFFFF; }
      body.white .hamburger-menu.active .icon span:nth-child(2) {
        background: #000000; }
    .hamburger-menu.active .icon span:nth-child(3) {
      transform: rotate(-45deg);
      background: #FFFFFF; }
      body.white .hamburger-menu.active .icon span:nth-child(3) {
        background: #000000; }
    .hamburger-menu.active .icon span:nth-child(4) {
      display: none; }

.logo {
  position: absolute;
  font-size: 0 !important;
  line-height: 0 !important;
  text-shadow: unset !important;
  transition: 250ms ease-in-out;
  display: block;
  background-image: url("../images/logo-white.svg");
  width: 150px;
  height: 70px;
  background-position: center left;
  z-index: 1;
  left: 15px;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat; }
  @media screen and (min-width: 620px) {
    .logo {
      width: 200px;
      height: 90px;
      left: 30px; } }
  @media screen and (min-width: 1024px) {
    .logo {
      width: 300px;
      height: 160px;
      left: 50px; } }
  @media screen and (min-width: 1212px) {
    .logo {
      width: 360px; } }
  @media screen and (min-width: 1350px) {
    .logo {
      width: 400px; } }
  body.white .logo {
    background-image: url("../images/logo-black.svg"); }

.mc-field-group input[type=text],
.mc-field-group input[type=email],
.mc-field-group input[type=phone],
.mc-field-group input[type=password],
.mc-field-group textarea,
.mc-field-group select {
  width: 100%; }

.mc-field-group.input-group > strong {
  display: block;
  margin-bottom: 1.25rem; }

.mc-field-group.input-group > ul {
  padding: 0;
  margin: 0; }

.indicates-required {
  color: #767676;
  font-style: italic;
  text-align: right;
  font-size: 0.8125rem; }
  @media screen and (min-width: 620px) {
    .indicates-required {
      font-size: 0.9375rem; } }

#mc-embedded-subscribe {
  margin-top: 1.25rem; }

figure figcaption {
  margin: 0.75rem 0; }

@media screen and (min-width: 620px) {
  figure {
    margin: 1.5rem 0; } }

@media screen and (min-width: 1350px) {
  figure {
    margin: 2.125rem 0; } }

figure img {
  max-width: 100%; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .video-container.tv {
    padding-bottom: 75%; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.social-media-icons ul {
  display: flex;
  list-style: none;
  padding: 0; }
  .social-media-icons ul > li {
    margin: 0 0 0 1.25rem; }
    .social-media-icons ul > li:first-child {
      margin-left: 0; }
    .social-media-icons ul > li > a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.625rem 0 0;
      width: 40px;
      height: 40px; }
      .social-media-icons ul > li > a .name {
        position: absolute !important;
        clip: rect(1px 1px 1px 1px);
        /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden; }
      .social-media-icons ul > li > a:focus, .social-media-icons ul > li > a:active, .social-media-icons ul > li > a:hover {
        transition: 250ms ease-in-out;
        color: #1879CD;
        text-decoration: none; }
      .social-media-icons ul > li > a.fb {
        position: relative;
        width: 40px;
        height: 40px; }
        .social-media-icons ul > li > a.fb::before {
          width: 40px;
          height: 40px;
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url("../images/social-fb-white.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0 !important;
          line-height: 0 !important;
          text-shadow: unset !important;
          transform-origin: center;
          transition: width 250ms ease-in-out, height 250ms ease-in-out, opacity 250ms ease-in-out, transform 250ms ease-in-out;
          transform: translate(-50%, -50%); }
        .social-media-icons ul > li > a.fb::after {
          width: 40px;
          height: 40px; }
      .social-media-icons ul > li > a.ig {
        position: relative;
        width: 40px;
        height: 40px; }
        .social-media-icons ul > li > a.ig::before {
          width: 40px;
          height: 40px;
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url("../images/social-ig-white.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0 !important;
          line-height: 0 !important;
          text-shadow: unset !important;
          transform-origin: center;
          transition: width 250ms ease-in-out, height 250ms ease-in-out, opacity 250ms ease-in-out, transform 250ms ease-in-out;
          transform: translate(-50%, -50%); }
        .social-media-icons ul > li > a.ig::after {
          width: 40px;
          height: 40px; }
      .social-media-icons ul > li > a.yt {
        position: relative;
        width: 40px;
        height: 40px; }
        .social-media-icons ul > li > a.yt::before {
          width: 40px;
          height: 40px;
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url("../images/social-yt-white.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0 !important;
          line-height: 0 !important;
          text-shadow: unset !important;
          transform-origin: center;
          transition: width 250ms ease-in-out, height 250ms ease-in-out, opacity 250ms ease-in-out, transform 250ms ease-in-out;
          transform: translate(-50%, -50%); }
        .social-media-icons ul > li > a.yt::after {
          width: 40px;
          height: 40px; }
    .social-media-icons ul > li:last-child a {
      padding-right: 0; }

header {
  position: relative;
  z-index: 11;
  min-height: 70px; }
  @media screen and (min-width: 620px) {
    header {
      min-height: 90px; } }
  @media screen and (min-width: 1024px) {
    header {
      min-height: 160px; } }

nav {
  position: absolute;
  opacity: 0;
  z-index: 10;
  top: 70px;
  right: 0;
  transition: 250ms ease-in-out; }
  @media screen and (max-width: 619px) {
    nav {
      background: #000000;
      right: -120px; } }
  @media screen and (min-width: 620px) {
    nav {
      top: 2px;
      right: 30px; } }
  @media screen and (min-width: 1024px) {
    nav {
      top: 37px; } }
  body.nav-open nav {
    opacity: 1; }
    @media screen and (max-width: 619px) {
      body.nav-open nav {
        right: 0; } }
    @media screen and (min-width: 620px) {
      body.nav-open nav {
        right: 80px; } }
    @media screen and (min-width: 1024px) {
      body.nav-open nav {
        right: 100px; } }
  nav ul {
    display: flex; }
    @media screen and (max-width: 619px) {
      nav ul {
        flex-direction: column;
        margin-top: 0.9375rem; } }
    @media screen and (max-width: 619px) {
      nav ul li {
        text-align: right;
        margin-bottom: 20px; }
        nav ul li:last-child {
          margin-bottom: 0; } }
    nav ul li a {
      color: #FFFFFF;
      padding: 20px;
      font-weight: 600;
      letter-spacing: .1em; }
      @media screen and (min-width: 620px) {
        body.white nav ul li a {
          color: #000000; } }
      @media screen and (min-width: 1024px) {
        nav ul li a {
          font-size: 1.25rem; } }
      nav ul li a span {
        display: inline-block;
        border-top: solid 2px transparent;
        transition: 250ms ease-in-out; }
      nav ul li a:focus, nav ul li a:active, nav ul li a:hover {
        transition: 250ms ease-in-out;
        text-decoration: none; }
        nav ul li a:focus span, nav ul li a:active span, nav ul li a:hover span {
          border-color: #FFFFFF; }
          body.white nav ul li a:focus span, body.white nav ul li a:active span, body.white nav ul li a:hover span {
            border-color: #000000; }

.slideshow-grid {
  margin: 0 15px 30px; }
  @media screen and (min-width: 620px) {
    .slideshow-grid {
      margin: 0 30px 30px; } }
  @media screen and (min-width: 1024px) {
    .slideshow-grid {
      margin: 0 50px 50px; } }
  .slideshow-grid ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 15px; }
    @media screen and (min-width: 620px) {
      .slideshow-grid ul {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        grid-gap: 30px; } }
    .slideshow-grid ul li {
      display: block;
      position: relative; }
      .slideshow-grid ul li::after {
        content: '';
        display: block;
        padding-bottom: 56.25%; }
      .slideshow-grid ul li > button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
  .slideshow-grid button {
    position: static;
    width: auto;
    height: auto;
    border: 0;
    padding: 0;
    background-color: transparent;
    line-height: inherit;
    text-align: inherit;
    color: #000000;
    outline: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%; }

.slideshow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%; }
  @media screen and (max-width: 619px) {
    .slideshow {
      width: 100vw;
      overflow-x: hidden; } }
  body.home .slideshow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    body.home .slideshow .slide,
    body.home .slideshow .slick-track,
    body.home .slideshow .slick-list {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      height: 100%;
      width: 100%; }
  .slideshow .slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
    body.home .slideshow .slide::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.3); }
  .slideshow .caption {
    display: none !important;
    color: #FFFFFF;
    font-size: 1.25rem;
    position: absolute;
    z-index: 12;
    transition: 250ms ease-in-out;
    width: 220px;
    left: 15px;
    bottom: 6%;
    padding-top: 0.625rem;
    border-top: solid 4px #FFFFFF; }
    @media screen and (max-width: 619px) {
      .slideshow .caption {
        width: 275px;
        left: 15px;
        bottom: -40px;
        border-top: 0;
        font-size: 1rem; }
        body.home .slideshow .caption {
          bottom: 6%;
          border-top: solid 3px #FFFFFF; } }
    @media screen and (min-width: 620px) {
      .slideshow .caption {
        width: 300px;
        left: 30px;
        bottom: 24px; } }
    @media screen and (min-width: 1024px) {
      .slideshow .caption {
        left: 50px;
        display: none; }
        body.home .slideshow .caption {
          display: block; } }
    @media screen and (min-width: 1212px) {
      .slideshow .caption {
        width: 400px; } }
    @media screen and (min-width: 1350px) {
      .slideshow .caption {
        width: 480px; } }

.slick-list {
  overflow: visible; }

.slideshow-container {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: 250ms ease-in-out;
  opacity: 0;
  background: rgba(255, 255, 255, 0.95); }
  body.slideshow-open .slideshow-container {
    visibility: visible;
    opacity: 1; }
  .slideshow-container > .slideshow:focus, .slideshow-container > .slideshow:active, .slideshow-container > .slideshow:hover {
    transition: 250ms ease-in-out; }
    .slideshow-container > .slideshow:focus .slick-arrow,
    .slideshow-container > .slideshow:focus .slick-dots, .slideshow-container > .slideshow:active .slick-arrow,
    .slideshow-container > .slideshow:active .slick-dots, .slideshow-container > .slideshow:hover .slick-arrow,
    .slideshow-container > .slideshow:hover .slick-dots {
      display: block !important;
      animation: fade-in 250ms ease-in-out; }
    .slideshow-container > .slideshow:focus .slick-dots, .slideshow-container > .slideshow:active .slick-dots, .slideshow-container > .slideshow:hover .slick-dots {
      display: none !important; }
      @media screen and (max-width: 619px) {
        .slideshow-container > .slideshow:focus .slick-dots, .slideshow-container > .slideshow:active .slick-dots, .slideshow-container > .slideshow:hover .slick-dots {
          display: none !important; } }
    .slideshow-container > .slideshow:focus .caption, .slideshow-container > .slideshow:active .caption, .slideshow-container > .slideshow:hover .caption {
      display: block;
      animation: fade-in 250ms ease-in-out; }
  .slideshow-container .slick-track {
    height: 100dvh; }
    @media screen and (max-width: 1023px) {
      .slideshow-container .slick-track {
        position: absolute; } }
  .slideshow-container .slick-slide > div {
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available; }
  .slideshow-container .slick-slide .slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw !important;
    max-width: 142.22222222222223vh;
    height: 80vh; }
    @media screen and (min-width: 1024px) {
      .slideshow-container .slick-slide .slide.vert {
        width: 54vh !important; } }
    @media screen and (max-height: 600px) {
      .slideshow-container .slick-slide .slide {
        max-width: 100vh; } }
    @media screen and (max-width: 1023px) {
      .slideshow-container .slick-slide .slide {
        width: 100vw !important;
        max-width: 98vw;
        height: 100vh;
        max-height: 70vw; }
        .slideshow-container .slick-slide .slide.vert {
          max-height: 72.5vh; } }

.slick-dots {
  display: none !important;
  list-style: none;
  padding: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  bottom: -30px;
  color: #FFFFFF; }
  .slick-dots li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background: rgba(0, 0, 0, 0.5);
    border: 0; }
    .slick-dots li.slick-active {
      background-color: rgba(255, 255, 255, 0.95); }
  .slick-dots button {
    color: #FFFFFF;
    font-size: 0 !important;
    line-height: 0 !important;
    text-shadow: unset !important; }

.slick-arrow {
  display: block;
  place-items: center;
  background: white;
  opacity: .8;
  border-radius: 360px;
  padding: 18px;
  position: absolute;
  z-index: 100;
  bottom: 30px;
  font-size: 0 !important;
  line-height: 0 !important;
  text-shadow: unset !important;
  outline: 0; }
  @media screen and (max-width: 1023px) {
    .slick-arrow {
      padding: 12px; } }

.slick-prev {
  left: 35px; }
  @media screen and (min-width: 1024px) {
    .slick-prev {
      left: 55px; } }
  @media screen and (min-width: 1024px) {
    .slick-prev {
      margin-top: 160px; } }
  .slick-prev::after {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    border-width: 2px 0 0 2px;
    border-color: #000000;
    border-style: solid;
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
    position: relative;
    right: -3px; }
    @media screen and (max-width: 1023px) {
      .slick-prev::after {
        width: 12px;
        height: 12px; } }

.slick-next {
  right: 35px; }
  @media screen and (min-width: 1024px) {
    .slick-next {
      right: 55px; } }
  .slick-next::after {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    border-width: 2px 0 0 2px;
    border-color: #000000;
    border-style: solid;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    position: relative;
    left: -3px; }
    @media screen and (max-width: 1023px) {
      .slick-next::after {
        width: 12px;
        height: 12px; } }

body.about {
  background: #FFFFFF; }
  body.about main {
    max-width: 1540px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%; }
    body.about main::after {
      display: block;
      clear: both;
      content: "" !important; }
    @media screen and (min-width: 620px) {
      body.about main {
        padding-right: 30px;
        padding-left: 30px; } }
    @media screen and (min-width: 1024px) {
      body.about main {
        padding-right: 50px;
        padding-left: 50px; } }
    @media screen and (max-width: 619px) {
      body.about main {
        margin-top: 0rem; } }
    @media screen and (min-width: 620px) and (max-width: 1023px) {
      body.about main {
        margin-top: 3.125rem; } }
  body.about section {
    margin-bottom: 3.125rem; }
    @media screen and (min-width: 620px) {
      body.about section {
        margin-bottom: 5rem; } }
    @media screen and (min-width: 1024px) {
      body.about section {
        margin-bottom: 6.25rem; } }
    @media screen and (min-width: 1024px) {
      body.about section.intro {
        margin-bottom: 3.125rem; } }
    @media screen and (min-width: 1350px) {
      body.about section.intro {
        margin-bottom: 0; } }
  @media screen and (min-width: 1024px) {
    body.about .squish {
      width: 50%; } }
  body.about h2 {
    display: inline-block;
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: bold;
    margin-bottom: 0; }
    @media screen and (min-width: 1024px) {
      body.about h2 {
        font-size: 1.125rem;
        line-height: 1.3334; } }
  body.about p {
    font-size: 0.875rem;
    line-height: 1.4286;
    margin: 1.25rem 0 0; }
    @media screen and (min-width: 1024px) {
      body.about p {
        padding-right: 10%;
        font-size: 1.0625rem;
        line-height: 1.4118; } }
  @media screen and (min-width: 1024px) {
    body.about .bio {
      display: flex; } }
  body.about .bio h2 {
    padding-top: 0.625rem;
    margin: 0 0 0.625rem;
    border-top: solid 4px #000000; }
    @media screen and (min-width: 1024px) {
      body.about .bio h2 {
        margin: 30px 0 80px;
        border: 0;
        padding: 0; } }
  body.about .bio .text {
    position: relative; }
    @media screen and (min-width: 1024px) {
      body.about .bio .text {
        width: 60%;
        align-self: flex-end;
        order: 1; }
        body.about .bio .text::before {
          content: "";
          position: absolute;
          z-index: 1;
          transition: 250ms ease-in-out;
          display: block;
          background: #000000;
          width: 90%;
          height: 6px; } }
  body.about .bio .photo {
    width: 50%;
    float: left;
    padding: 0 20px 20px 0; }
    @media screen and (min-width: 620px) {
      body.about .bio .photo {
        padding: 0 40px 40px 0; } }
    @media screen and (min-width: 1024px) {
      body.about .bio .photo {
        float: none;
        order: 2;
        padding: 0; } }
    body.about .bio .photo img {
      width: 100%; }

body.contact .layout-container {
  position: fixed; }

section.contact {
  position: fixed;
  bottom: 15px;
  left: 15px;
  border-top: solid 4px #FFFFFF;
  color: #FFFFFF;
  padding-right: 30px;
  padding-top: 0.625rem; }
  section.contact p {
    line-height: 1.4; }
    section.contact p a {
      color: #FFFFFF; }
      section.contact p a:hover {
        text-decoration: none; }
  @media screen and (min-width: 620px) {
    section.contact {
      left: 30px;
      bottom: 30px; } }
  @media screen and (min-width: 1024px) {
    section.contact {
      left: 50px;
      bottom: 45px; } }

body.home main {
  max-width: 1540px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  body.home main::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (min-width: 620px) {
    body.home main {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1024px) {
    body.home main {
      padding-right: 50px;
      padding-left: 50px; } }
  @media screen and (max-width: 1023px) {
    body.home main {
      z-index: 0; } }

body.home h1, body.home p {
  color: #000000; }

body.work {
  background: #FFFFFF; }
