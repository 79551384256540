.logo {
  position: absolute;
  @include hide-text;
  @include transition;
  display: block;
  background-image: url($images-path + 'logo-white.svg');
  width: 150px;
  height: 70px;
  background-position: center left;
  z-index: 1;
  left: $gutter-mobile;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;

  @include mq($tablet) {
    width: 200px;
    height: 90px;
    left: $gutter-tablet;
  }

  @include mq($desktop) {
    width: 300px;
    height: 160px;
    left: $gutter-desktop;
  }

  @include mq($desktop-med) {
    width: 360px;
  }

  @include mq($desktop-plus) {
    width: 400px;
  }

  body.white & {
    background-image: url($images-path + 'logo-black.svg');
  }

}//.logo
