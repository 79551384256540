.slideshow-grid {
  margin: 0 $gutter-mobile 30px;

  @include mq($tablet) {
    margin: 0 $gutter-tablet 30px;
  }

  @include mq($desktop) {
    margin: 0 $gutter-desktop 50px;
  }

  ul {
    @include no-bullet;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 15px;

    @include mq($tablet) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      grid-gap: 30px;
    }



    li {
      @include aspect-ratio(percentage(9/16), 'button');


    }

  }

  button {
    @include remove-button-styles;
    @include background-cover;
    width: 100%;
  }

}
