.button {
  @include button;

  span {
    text-transform: lowercase;
  }

}

.forum-create-link-new {
  a {
    @include button;
  }
}
