header {
  position: relative;
  z-index: 11;
  min-height: $hdr-height-mobile;

  @include mq($tablet) {
    min-height: $hdr-height-tablet;
  }

  @include mq($desktop) {
    min-height: $hdr-height-desktop;
  }

}
