body.home {
	main { 
		@include grid-container;
	
		@include mq($tablet-max) {
			z-index: 0;
		}

	}
	
	h1, p { color: $black; }
}
