body.about {
  background: $white;

  main {
    @include grid-container;

    @include mq($mobile) {
      margin-top: rem(0);
    }

    @include mq($tablet-only) {
      margin-top: rem(50);
    }

  }

  section {
    margin-bottom: rem(50);

    @include mq($tablet) {
      margin-bottom: rem(80);
    }

    @include mq($desktop) {
      margin-bottom: rem(100);
    }

    &.intro {

      @include mq($desktop) {
        margin-bottom: rem(50);
      }

      @include mq($desktop-plus) {
        margin-bottom: 0;
      }

    }

  }

  .squish {
    @include mq($desktop) {
      width: 50%;
    }
  }

  h2 {
    display: inline-block;
    @include font(15, 24);
    font-weight: bold;
    margin-bottom: 0;

    @include mq($desktop) {
      @include font(18, 24);
    }

  }

  p {
    @include font(14, 20);
    margin: rem(20) 0 0;

    @include mq($desktop) {
      padding-right: 10%;
      @include font(17, 24);
    }

  }

  .bio {

    @include mq($desktop) {
      display: flex;
    }

    h2 {
      padding-top: rem(10);
      margin: 0 0 rem(10);
      border-top: solid 4px $black;

      @include mq($desktop) {
        margin: 30px 0 80px;
        border: 0;
        padding: 0;
      }
  
    }

    .text {
      position: relative;

      @include mq($desktop) {
        width: 60%;
        align-self: flex-end;
        order: 1;

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          @include transition;
          display: block;
          background: $black;
          width: 90%;
          height: 6px;
        }

      }

    }
    .photo {
      width: 50%;
      float: left;
      padding: 0 20px 20px 0;

      @include mq($tablet) {
        padding: 0 40px 40px 0;
      }

      @include mq($desktop) {
        float: none;
        order: 2;
        padding: 0;
      }

      img {
        width: 100%;
      }

    }

  }

}
