body.contact {
  .layout-container {
    position: fixed;
  }
}

section.contact {
  position: fixed;
  bottom: $gutter-mobile;
  left: $gutter-mobile;
  border-top: solid 4px $white;
  color: $white;
  padding-right: 30px;
  padding-top: rem(10);

  p {
    line-height: 1.4;

    a {color: $white;
      &:hover {
        text-decoration: none;
      }

    }
  }

  @include mq($tablet) {
    left: $gutter-tablet;
    bottom: $gutter-tablet;
  }

  @include mq($desktop) {
    left: $gutter-desktop;
    bottom: $gutter-desktop - 5px;
  }

}
