nav {
  position: absolute;
  opacity: 0;
  z-index: 10;
  top: 70px;
  right: 0;
  @include transition;

  @include mq($mobile) {
    background: $black;
    right: -120px;
  }

  @include mq($tablet) {
    top: 2px;
    right: 30px;
  }

  @include mq($desktop) {
    top: 37px;
  }

  body.nav-open & {
    opacity: 1;

    @include mq($mobile) {
      right: 0;
    }

    @include mq($tablet) {
      right: 80px;
    }

    @include mq($desktop) {
      right: 100px;
    }

  }

  ul {
    display: flex;

    @include mq($mobile) {
      flex-direction: column;
      margin-top: rem(15);
    }

    li {

      @include mq($mobile) {
        text-align: right;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

      }

      a {
        color: $white;
        padding: 20px;
        font-weight: 600;
        letter-spacing: .1em;

        body.white & {

          @include mq($tablet) {
            color: $black;
          }

        }

        @include mq($desktop) {
          @include font(20);
        }

        span {
          display: inline-block;
          border-top: solid 2px transparent;
          @include transition;
        }

        @include hover {
          text-decoration: none;

          span {
            border-color: $white;

            body.white & {
              border-color: $black;
            }

          }

        }

      }

    }

  }

}
