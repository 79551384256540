// GLOBAL LAYOUT ELEMENTS

* {
  -webkit-overflow-scrolling: touch;
}

html,
body {
  overflow-x: hidden;
  background: $black;

  &.slideshow-open {
    //overflow-y: hidden;

    .layout-container {
      position: fixed;
    }
  }
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}


main {
  @include clearfix;
  min-height: 500px;

  @include mq($tablet-max) {
    position: relative;
    z-index: 2;
    min-height: 300px;
  }

  // desktop sticky nav
  body.fixed-nav & {

    @include mq($desktop) {
      padding-top: 60px;
    }

  }//.mobile-nav-open &

}//main#main

article {
  @include grid-container;
}

.layout-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  @include transition;

  // mobil nav open
  body.mobile-nav-open & {

    @include mq($tablet-max) {
      position: fixed;
      width: 100%;
    }

  }//.mobile-nav-open &

}//.layout-container

.layout-inner-container {
  position: relative;
  @include grid-container;
}//.grid-container

figure,
.image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.thumb-list {

  .views-row {
    margin-bottom: rem(30);

    @include mq($tablet) {
      margin-bottom: rem(40);
    }

    @include mq($desktop-plus) {
      margin-bottom: rem(56);
    }

  }

}//.thumb-list
