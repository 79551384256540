
.slideshow {
  @include fit-container;
	height: 100%;
  width: 100%;

  @include mq($mobile) {
    width: 100vw;
    overflow-x: hidden;
  }


  body.home & {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .slide,
	  .slick-track,
	  .slick-list {
		  @include fit-container;
      background-size: cover;
		  height: 100%;
		  width: 100%;
    }

  }


	.slide {
		@include background-cover;
    background-size: contain;

    body.home & {
      &::after {
        content: '';
        @include fit-container;
        z-index: 1;
        height: 100%;
        width: 100%;
        background: $black-light-transparent;
      }
    }

	}

	.caption {
    display: none !important;
		color: $white;
		@include font(20);
		position: absolute;
		z-index: 12;
		@include transition;
		width: 220px;
		left: $gutter-mobile;
		bottom: 6%;
		padding-top: rem(10);
		border-top: solid 4px $white;

    @include mq($mobile) {
      width: 275px;
      left: $gutter-mobile;
      bottom: -40px;
      border-top: 0;
      @include font(16);

      body.home & {
        bottom: 6%;
        border-top: solid 3px $white;
      }

    }

		@include mq($tablet) {
			width: 300px;
			left: $gutter-tablet;
			bottom: 24px;
		}

		@include mq($desktop) {
      left: $gutter-desktop;
      display: none;

      body.home & {
        display: block;
      }

		}

		@include mq($desktop-med) {
			width: 400px;
		}

		@include mq($desktop-plus) {
			width: 480px;
		}

	}//.logo

}//.slideshow

.slick-list {
  overflow: visible;
}

// special container on "Work" page

.slideshow-container {
  visibility: hidden;
  @include fit-container;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  @include transition;
  opacity: 0;
  background: $white-transparent;

  body.slideshow-open & {
    visibility: visible;
    opacity: 1;
  }


  > .slideshow {

    @include hover {

      .slick-arrow,
      .slick-dots {
        display: block !important;
        @include fade-in;
      }

      .slick-dots {
        display: none !important;

        @include mq($mobile) {
          display: none !important;
        }

      }

      .caption {
        display: block;
        @include fade-in;
      }

    }//@include hover

  }

  .slide,
  .slick-track,
  .slick-list {
    //@include fit-container;
    //height: 100%;
    //width: 100%;
  }

  .slick-track {
    height: 100dvh;

    @include mq($tablet-max) {
      position: absolute;
      //margin-top: $hdr-height-tablet;
    }

    @include mq($mobile) {
      //margin-top: $hdr-height-mobile;
    }
  }

  .slick-slide {

    > div {
      display: grid;
      place-items: center;
      width: 100vw;
      height: 100vh;
      max-height: -webkit-fill-available;
    }

    .slide {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80vw !important;
      max-width: 142.22222222222223vh;
      height: 80vh;

      @include mq($desktop) {
        &.vert {
          width: 54vh !important;
        }
      }


      @include mq($short-screen) {
        max-width: 100vh;
      }

      @include mq($tablet-max) {
        width: 100vw !important;
        max-width: 98vw;
        height: 100vh;
        max-height: 70vw;

        &.vert {
          max-height: 72.5vh;
        }
      }
    }
  }
}

.slick-dots {
  display: none !important;
  @include no-bullet;
  @include centered(x);
  display: flex;
  bottom: -30px;
  color: $white;

  li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background: $black-transparent;
    //border-radius: 50%;
    border: 0;

    &.slick-active {
      background-color: $white-transparent;
    }
  }

  button {
    color: $white;
    @include hide-text;
  }

}

.slick-arrow {
  display: block;
  place-items: center;
  background: white;
  opacity: .8;
  border-radius: 360px;
  padding: 18px;
  position: absolute;
  z-index: 100;
  bottom: 30px;
  @include hide-text;
  outline: 0;

  @include mq($tablet-max) {
    padding: 12px;
  }

}

.slick-prev {
  left: 35px;

  @include mq($desktop) {
    left: 55px;
  }

  @include mq($desktop) {
    margin-top: $hdr-height-desktop;
  }

  &::after {
    content: '';
    @include chevron(18px, 2px, $black, left);
    position: relative;
    right: -3px;

    @include mq($tablet-max) {
      width: 12px;
      height: 12px;
    }
  }

}

.slick-next {
  right: 35px;

  @include mq($desktop) {
    right: 55px;
  }

  &::after {
    content: '';
    @include chevron(18px, 2px, $black, right);
    position: relative;
    left: -3px;

    @include mq($tablet-max) {
      width: 12px;
      height: 12px;
    }
  }

}
